import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const UsersPage = () => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [logged, setLogged] = useState(false);
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    document.title = 'SpinBattleCasino | Userspage';

    const fetchData = async () => {
      try {
        const response = await fetch('/users/users.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const fetchedData = await response.json();
        console.log(fetchedData);
        setData(fetchedData);
      } catch (error) {
        console.error('Error /users/users.json', error);
      }
    };
    fetchData();
  }, []);

  const fetchDataLogin = async (dataObj) => {
    try {
      const response = await fetchFormData('/api/userspage-login.php', dataObj);

      if (response.status === 'success') {
        console.log('Login successful');
        setLogged(true);
      } else {
        setLoginError(response.message);
      }
    } catch (error) {
      console.error('Error /api/userspage-login.php:', error);
    }
  };

  function fetchFormData(url, param) {
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
      body: new URLSearchParams(param),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .catch((error) => {
        console.error('Error POST:', error);
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name !== '' && password !== '') {
      const dataObj = { login: name, password: password };
      fetchDataLogin(dataObj);
    }
  };

  const handleCheckboxChange = () => {
    setShowPassword(!showPassword);
  };

  if (!logged) {
    return (
      <div className="z-50 absolute top-0 l-0 w-full h-[100vh] bg-white flex justify-center text-black">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-center gap-[20px] border border-black rounded-lg w-full max-w-[500px] h-[500px] mt-[150px] px-10"
        >
          <h1 className="mt-10">{t('userspage:heading-1')}</h1>
          <input
            placeholder="Enter a full name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full font-sans px-6 py-2 text-black border border-black outline-0 rounded-full lg:py-[18px] lg:rounded-[10px]"
          />
          <input
            placeholder="Enter a password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full font-sans px-6 py-2 text-black border border-black outline-0 rounded-full lg:py-[18px] lg:rounded-[10px]"
          />
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={showPassword}
              onChange={handleCheckboxChange}
              id="showPassword"
              className="mr-2"
            />
            <label htmlFor="showPassword">{t('userspage:heading-2')}</label>
          </div>
          {loginError && <p className="text-red">{loginError}</p>}
          <button type="submit" className="px-10 h-[40px] bg-purple text-white">
            {t('userspage:heading-3')}
          </button>
        </form>
      </div>
    );
  }

  if (logged) {
    return (
      <section className="w-full flex flex-col items-center justify-center gap-[10px] min-h-[70vh] mt-16">
        {data && data.length > 0 ? (
          data.map((user) => (
            <div
              className="w-full max-w-[600px] border-2 border-white rounded-xl px-4 py-2"
              key={user}
            >
              <h1>{t('userspage:heading-4')} {user.name}</h1>
              <h2>{t('userspage:heading-5')} {user.email}</h2>
              <p>{t('userspage:heading-6')} {user.received}</p>
            </div>
          ))
        ) : (
          <h1 className="text-4xl font-bold text-center">{t('userspage:heading-7')}</h1>
        )}
      </section>
    );
  }
};

import React, { useContext, useEffect } from 'react';

import { RootContext } from '../context/RootContext';
import { useTranslation } from 'react-i18next';
import { Games } from './Games';

import landingBg from '../assets/images/bg-img1.png';
import landingBg2 from '../assets/images/land-img.png';


export const LandingPage = () => {
  const { checkForLoggedIn, isMenuOpen } = useContext(RootContext);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'SpinBattleCasino | Home';

    document.body.style.backgroundColor = '#070A23';
    document.body.style.backgroundImage = `url('${landingBg}')`;
    document.body.style.backgroundPosition = 'top 55%';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundRepeat = 'no-repeat';

    return () => {
      document.body.style.backgroundColor = '';
      document.body.style.backgroundImage = '';
      document.body.style.backgroundPosition = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundRepeat = '';
    };
  });

  useEffect(() => {
    document.body.style.position = isMenuOpen ? 'fixed' : 'relative';
  }, [isMenuOpen]);

  return (
      <section className="w-full px-2 flex flex-col items-center mt-[20px] lg:mt-[150px]">

        <div className='flex flex-col items-center'>
          <h1 className="text-[28px] lg:text-[42px] font-bold lg:leading-[54px] text-center">
            {t('landing:heading1')}
          </h1>

          <p className='text-[18px] mt-[40px] text-center lg:px-[200px]'>
            {t('landing:subheading1')}
          </p>
        </div>

        <div className='flex flex-col items-center justify-center mt-[68px] gap-[60px] lg:flex-row'>

          <div className='mt-50px p-6 bg-[#242B46]  lg:max-w-[544px] rounded-[24px]'>
            <h1 className='text-[28px] text-[#07CBEB]  mb-[20px] font-bold'>{t('landing:heading2')}</h1>
            <p className='text-[20px]'>{t('landing:subheading2')}</p>
          </div>

          <div className='mt-50px p-6 bg-[#242B46]  lg:max-w-[544px] rounded-[24px]'>
            <h1 className='text-[28px] text-[#07CBEB]  mb-[20px] font-bold'>{t('landing:heading3')}</h1>
            <p className='text-[20px]'>{t('landing:subheading3')}</p>
          </div>

          <div className='mt-50px p-6 bg-[#242B46]  lg:max-w-[544px] rounded-[24px]'>
            <h1 className='text-[28px] text-[#07CBEB]  mb-[20px] font-bold'>{t('landing:heading4')}</h1>
            <p className='text-[20px]'>{t('landing:subheading4')}</p>
          </div>
        </div>

        <Games/>

        <div className="w-full absolute bottom-0 left-0 z-[-1]">
          <img className="w-full h-full" src={landingBg2} alt={landingBg2}/>
        </div>


      </section>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/logo.png';
export const Footer = () => {
  const { t } = useTranslation();

  return (
      <footer
          className="lg:absolute bg-brown-secondary pb-5 w-full flex flex-col items-center lg:px-6 pt-[36px] lg:pb-[42px]">
          <a href="/" className="lg:m-auto">
              <img src={logo} alt="logo"/>
          </a>


          <div className="mt-[21px] px-2 flex flex-col items-center text-center m-auto md:max-w-[1016px] lg:text-center">
              <h3 className="text-[24px] font-bold lg:mt-[40px] lg:text-xl">{t('footer:heading-1')}</h3>
              {/* <p className="mt-[10px] text-[18px] leading-[28px]  lg:text-base">
                  {t('footer:subheading-1')}
              </p> */}
              <p className="mt-[10px] text-[18px] leading-[28px]  lg:text-base" dangerouslySetInnerHTML={{ __html: t('footer:subheading-1') }} />
          </div>

          <div
              className="font-montserrat text-base w-full flex flex-col items-center justify-center gap-[24px] mt-[34px] lg:mt-[40px] lg:text-xl lg:flex-row lg:items-center lg:justify-center lg:gap-8">
              <a href="/contact/" className="transition text-[14px] font-bold uppercase hover:text-tertiaryOrange">
                  Contact
              </a>

              <a href="/opt-out/" className="transition text-[14px] font-bold uppercase hover:text-tertiaryOrange">
                  Opt Out
              </a>

              <a href="/cookie-policy/" className="transition text-[14px] font-bold uppercase hover:text-tertiaryOrange">
                  Cookie policy
              </a>

              <a href="/disclaimer/" className="transition text-[14px] font-bold uppercase hover:text-tertiaryOrange">
                  Disclaimer
              </a>

              <a href="/terms-and-conditions/"
                 className="transition text-[14px] font-bold uppercase hover:text-tertiaryOrange">
                  Terms & Conditions
              </a>

              <a href="/privacy-policy/"
                 className="transition text-[14px] font-bold uppercase hover:text-tertiaryOrange">
                  Privacy Policy
              </a>


          </div>
      </footer>
  );
};

import React, {useContext, useEffect} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button } from '../components/index';
import { gamesList } from '../data/gamesList.js';
import { useTranslation } from 'react-i18next';
import {RootContext} from "../context/RootContext";
import {useNavigate} from "react-router";
import 'swiper/css';


export const Games = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loggedIn, checkForLoggedIn } = useContext(RootContext);


  useEffect(() => {
    document.title = 'SpinBattleCasino | Top-10 Games';
      document.body.style.backgroundColor = '#070A23';
  }, []);

  const saveGameAndOpenPoppup = (url) => {
      localStorage.setItem('selectedGame', url);
      checkForLoggedIn();
  }

  const startGame = (url) => {
      navigate(`/game/${url}`)
  }

  const limitedGamesList = gamesList.slice(0, 20); // Limiting to 20 games

    return (
        <section className="w-full py-10 px-4 flex flex-col items-center lg:mb-[600px] lg:mt-[110px]">
            <div className="flex flex-col text-center lg:px-[200px]">
                <h1 className="text-[36px] leading-[54px] mb-6 font-bold lg:text-[42px]">{t('landing:heading5')}</h1>
                <p className='text-[18px] leading-auto mb-6 lg:text-[24px]'>
                    {t('landing:subheading5')}
                </p>
            </div>

            <div className='flex flex-col gap-[40px] lg:max-w-[1335px] items-center justify-center flex-wrap lg:flex-row'>
                {limitedGamesList && limitedGamesList.map((game) => (
                    <SwiperSlide key={game.url} className='w-auto'>
                        <div
                            className="mb-4 max-w-[235px] gap-[13px] flex flex-wrap text-center">
                            <img
                                src={game.img}
                                alt={game.img}
                                className="rounded-[8px]  aspect-[235/181] object-cover"
                            />
                            <Button
                                type="button"
                                buttonClass="mini"
                                title={t('buttons:heading-1')}
                                onClick={loggedIn ? () => startGame(game.url) : () => saveGameAndOpenPoppup(game.url)}
                            />
                        </div>
                    </SwiperSlide>
                ))}

            </div>

        </section>
    );
};

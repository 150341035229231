import React, { useEffect, useState } from 'react';

import { Button } from '../components/index.js';
import { useTranslation } from 'react-i18next';
import contactImage from '../assets/images/contact-img.png';

export const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'SpinBattleCasino | Contact Us';
    document.body.style.backgroundColor = '#070A23';
  }, []);

  const validateInputs = () => {
    let isValid = true;

    if (name.trim().split(' ').length !== 2) {
      setNameError(t('contact:heading-3'));
      isValid = false;
    } else {
      setNameError('');
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(t('contact:heading-4'));
      isValid = false;
    } else {
      setEmailError('');
    }

    if (message.trim() === '') {
      setMessageError(t('contact:heading-5'));
      isValid = false;
    } else {
      setMessageError('');
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateInputs()) {
      setSubmitted(true);
      setName('');
      setEmail('');
      setMessage('');
    }
  };

  return (
      <section
          className="flex flex-col  items-center  mb-[220px] px-4 lg:justify-start lg:mt-[200px] lg:mb-[200px] lg:ml-[140px] lg:pb-[60px] lg:flex-row">
        <div className="bg-cover bg-no-repeat bottom-0 absolute lg:right-0 lg:top-[120px] lg:max-w-[1000px] z-[10]">
          <img
              src={contactImage}
              alt={'contact'}
              className="w-full"
          />
        </div>
        {submitted ? (
            <div className="h-[80vh] flex items-center justify-center">
              <div className="flex flex-col text-center">
                <h1 className="text-2xl font-bold lg:text-[42px]">{t('contact:heading-1')}</h1>
                <p>{t('about:subheading-1')}</p>
              </div>
            </div>
        ) : (
            <>
              <form
                  onSubmit={handleSubmit}
                  className="flex flex-col items-center gap-4 lg:pb-[60px] w-full lg:max-w-[713px] lg:items-start z-[20]"
              >
                <h1 className="text-[36px] m
                t-[40px] font-bold lg:text-[42px]">{t('contact:heading-2')}</h1>
                <p className="text-[18px] leading-[18px] lg:text-xl lg:mt-[15px]">
                  {t('contact:subheading-2')}
                </p>
                <input
                    placeholder="Full name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full font-montserrat px-6 py-3 mb-2 text-black outline-0 rounded-[8px] lg:py-[18px] lg:rounded-[10px]"
                />
                {nameError && <span className="text-red mt-[-10px] mb-[-10px]">{nameError}</span>}
                <input
                    placeholder="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full font-montserrat px-6 py-3 mb-2 text-black outline-0 rounded-[8px] lg:py-[18px] lg:rounded-[10px]"
                />
                {emailError && <span className="text-red mt-[-10px] mb-[-10px]">{emailError}</span>}
                <textarea
                    placeholder="Your message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="h-[200px] overflow-hidden resize-none w-full font-montserrat px-6 py-3 text-black outline-0 rounded-[8px] lg:py-[18px] lg:rounded-[10px]"
                />
                {messageError && <span className="text-red mt-[-10px] mb-[-10px]">{messageError}</span>}
                <div className="w-full lg:max-w-[320px] p-[6px]">
                  <Button title={t('buttons:heading-2')} buttonClass="large"/>
                </div>
              </form>

            </>
        )}
      </section>
  );
};

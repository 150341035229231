import React, { useEffect } from 'react';
import notFoundImg from "../assets/images/404-img.png"
import {Button} from "../components";
import { useTranslation } from 'react-i18next';

export const NotFoundPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'SpinBattleCasino | Not found page 404';
    console.error(`GET ${window.location.href} 404 (Not Found)`);
  }, []);

  return (
      <section className="bg-[url('./assets/images/404-bg.png')] bg-cover bg-center bg-no-repeat h-full pt-[100px] pb-[200px]">

          <div className="flex items-center justify-center">
              <img src={notFoundImg} alt="not-found-img" />
          </div>

          <div className="flex flex-col items-center text-center justify-center">


              <h1 className="text-[36px] leading-[54px] mb-6 font-bold  lg:text-[42px]">{t('notfound:heading-1')}</h1>

              <p className='text-[18px] font-bold leading-auto'>
                  {t('notfound:heading-2')}
              </p>

              <div className="mt-[30px]">
                  <Button
                      buttonClass="long"
                      title={t('buttons:heading-7')}
                      anchorLink
                      anchorHref={`/`}
                  />
              </div>
          </div>
      </section>
  );
};

import React from 'react';

import { Route, Routes } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Layout, LayuotWithoutHeaderAndFooter } from './components/index';

import {
    About,
    Contact,
    CookiePolicy,
    Disclaimer,
    Game,
    Home,
    NotFoundPage,
    OptOut,
    TermsAndConditions,
    UsersPage,
    Games,
    LandingPage,
    PrivacyPolicy,
    Terms,
} from './pages/index';

import './index.css';

export const App = () => {
    const { t } = useTranslation();
    return (
<>
    <div className="z-[40] sticky top-0 flex p-2 items-center justify-center h-[40px] bg-darkBlueBg lg:h-[50px]">
        <span className="font-montserrat text-sm font-normal">
         {t('home:heading-6')}
        </span>
            </div>
            <Routes>
                <Route element={<Layout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/about/" element={<About />} />
                    <Route path="/opt-out/" element={<OptOut />} />
                    <Route path="/contact/" element={<Contact />} />
                    <Route path="/games/" element={<Games />} />
                    <Route path="/game/:url/" element={<Game />} />
                    <Route path="/lp/" element={<LandingPage />} />
                    <Route path="/terms-and-conditions/" element={<TermsAndConditions />} />
                    <Route path="/cookie-policy/" element={<CookiePolicy />} />
                    <Route path="/privacy-policy/" element={<PrivacyPolicy />} />
                    <Route path="/disclaimer/" element={<Disclaimer />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Route>
                <Route element={<LayuotWithoutHeaderAndFooter />}>
                    <Route path="/userspage/" element={<UsersPage />} />
                    <Route path="/terms/" element={<Terms />} />
                </Route>
            </Routes>
        </>
    );
};

import React, {useContext, useEffect, useState} from 'react';
import { RootContext } from '../context/RootContext';
import { useTranslation } from 'react-i18next';
import { Button } from '../components/index';

import gameImage from '../assets/images/game-1.png';
import homeImg2 from '../assets/images/home-img-2.png'
import landingBg from '../assets/images/bg-img1.png';
import sparky from '../assets/images/sparky.png';
import shortz from '../assets/images/shortz.png';

export const Home = () => {
  const { checkForLoggedIn, isMenuOpen } = useContext(RootContext);
  const { t } = useTranslation();

    useEffect(() => {
        localStorage.removeItem('selectedGame')
    },[])

    useEffect(() => {
        document.title = 'SpinBattleCasino | Home';

        document.body.style.backgroundColor = '#070A23';
        // document.body.style.backgroundImage = `url('${landingBg}')`;
        // document.body.style.backgroundSize = 'cover';
        // document.body.style.backgroundPosition = 'center';
        // document.body.style.backgroundRepeat = 'no-repeat';

        return () => {
            document.body.style.backgroundColor = '';
            // document.body.style.backgroundImage = '';
            // document.body.style.backgroundPosition = '';
            // document.body.style.backgroundSize = '';
            // document.body.style.backgroundRepeat = '';
        };
    });

    useEffect(() => {
        document.body.style.position = isMenuOpen ? 'fixed' : 'relative';
    }, [isMenuOpen]);

  return (
      <div className='relative flex flex-col items-center w-full lg:pt-16'>
        <section
            className="pt-[30px] px-6 overflow-hidden lg:mb-0  lg:pb-[250px] max-w-[1220px]">
            <div className='absolute top-[320px] right-[250px] z-[-1] w-full lg:top-0 lg:right-0' >
                <img src={landingBg} alt='bg' className='h-auto min-w-[880px]'/>
            </div>

              <div className="flex flex-col items-center text-center justify-center lg:text-left lg:items-start lg:w-full">
                <h1 className="order-1 text-[36px] eading-[48px] font-bold lg:text-[36px] lg:leading-[54px] lg:mt-[25px] lg:mb-[0px] z-10">
                    {t('home:heading-1')}
                </h1>

                <p className="order-3 text-[16px] leading-[24px] mt-[24px] lg:text-[18px] lg:leading-[28px] lg:order-2 lg:mt-[23px] ">
                    {t('home:subheading-1')}
                </p>
                <div className="mt-[24px] order-3  lg:order-3">
                  <Button title="Play Now" buttonClass="biggest" onClick={checkForLoggedIn}/>
                </div>
              </div>

              <div className='flex flex-col mt-[100px] lg:flex-row lg:mt-[600px]'>

                <div className='flex items-center justify-center mt-[92px] mb-[24px] lg:mt-0 lg:mb-0 lg:min-w-[300px]'>
                  <img className="w-full" src={homeImg2} alt={homeImg2}/>
                </div>

                <div className='flex flex-col items-center justify-center text-center lg:max-w-[744px] lg:items-start lg:text-start lg:ml-[120px]'>
                  <h1 className="order-1 text-[36px] uppercase leading-[48px] font-bold lg:text-[42px] lg:leading-[54px] lg:mt-0 lg:mb-[0px]">
                    {t('home:heading-2')}
                  </h1>
                  <p className="order-3 w-full text-[16px] mb-[50px] leading-[24px] mt-[24px] lg:text-[18px] lg:leading-[28px] lg:order-2">
                      {t('home:subheading-2')}
                  </p>
                </div>
              </div>
        </section>


        <section className="bg-[url('./assets/images/home-bg-2.png')] bg-center mt-20 w-full bg-cover bg-no-repeat pb-40">
          <div className='flex flex-col text-center px-3 lg:items-center  justify-center lg:text-center lg:mt-0 mb-[60px] '>
            <h1 className="order-1 text-[36px] leading-[48px] uppercase font-bold lg:max-w-[640px] lg:text-[36px] lg:leading-[54px] lg:mt-20">
                {t('home:heading-3')}
            </h1>
            <p className="order-3 text-[16px] leading-[24px] mt-[24px] lg:max-w-[640px]  lg:text-[16px] lg:leading-[28px] lg:order-2 lg:mt-[23px]">
                {t('home:subheading-3')}
                {t('home:subheading-3_1')}

            </p>
          </div>
        </section>


          <section className="flex flex-col order-2 mb-[100px] justify-center items-center lg:order-1 lg:mt-[100px]">
              <h2 className='text-[40px] mt-[100px] text-center font-bold lg:mb-[50px]'>{t('home:heading-4')}</h2>
              <div className='flex flex-col items-center justify-between max-w-[1554px] lg:flex-row'>
                  <div className='flex flex-col lg:flex-row'>

                      <div
                          className='flex items-center justify-center mt-[40px] mb-[24px] lg:mt-0 lg:mb-0 lg:min-w-[300px]'>
                          <img className="w-50" src={sparky} alt={sparky}/>
                      </div>

                      <div
                          className='px-6 flex flex-col items-center justify-center text-center lg:max-w-[744px] lg:items-start lg:text-start lg:ml-[20px]'>
                          <h1 className="order-1 text-[26px] uppercase leading-[48px] font-bold lg:text-[42px] lg:leading-[54px] lg:mt-0 lg:mb-[0px]">
                              {t('home:heading-4_1')}
                          </h1>
                          <p className="order-3 text-[18px] mb-[50px] leading-[24px] mt-[24px] lg:text-[18px] lg:leading-[28px] lg:order-2">
                              {t('home:subheading-4_1')}
                          </p>
                      </div>
                  </div>

                  <div className='flex flex-col lg:flex-row'>

                      <div
                          className='flex flex-col items-center w-full justify-center mt-[40px] mb-[24px] lg:mt-0 lg:mb-0 lg:min-w-[300px]'>
                          <img className="w-50" src={shortz} alt={shortz}/>
                      </div>

                      <div
                          className='px-6 flex flex-col items-center justify-center text-center lg:max-w-[744px] lg:items-start lg:text-start'>
                          <h1 className="order-1 text-[26px] uppercase  font-bold leading-8 lg:text-[42px] lg:leading-[54px] lg:mt-0 lg:mb-[0px]">
                              {t('home:heading-4_2')}
                          </h1>
                          <p className="order-3 text-[18px] mb-[50px] leading-[24px] mt-[24px] lg:text-[18px] lg:leading-[28px] lg:order-2">
                              {t('home:subheading-4_2')}
                          </p>
                      </div>
                  </div>

              </div>


          </section>


          <section className="bg-textBlack order-1  mx-4 my-8 lg:flex lg:mb-[200px] lg:order-2  rounded-[18px]">

              <div className="">
                  <img className="w-full h-full" src={gameImage} alt={gameImage}/>
              </div>

              <div
                  className='flex flex-col px-2 text-center items-center justify-center lg:items-start lg:text-start lg:mx-[100px]'>
                  <h1 className="text-[42px]  font-bold leading-[54px] mt-10 lg:text-[36px] lg:max-w-[590px] lg:leading-[54px] ">
                      {t('home:heading-5')}
                  </h1>

                  <p className="text-[16px] leading-[24px] mt-[24px] lg:text-[18px] lg:leading-[28px] lg:max-w-[590px] lg:order-2 lg:mt-[23px]">
                      {t('home:subheading-5')}
                  </p>

                  <div className='mt-[26px] mb-[51px] lg:order-3'>
                      <Button buttonClass="biggest" title={t('buttons:heading-1')} type="button" onClick={checkForLoggedIn}/>
                  </div>
              </div>
          </section>

      </div>
  );
};

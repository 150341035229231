import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from '../components/Button';

import optOutImage from '../assets/images/opt-out-img.png';


export const OptOut = () => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [submitted, setSubmitted] = useState(false);


  useEffect(() => {
    document.title = 'SpinBattleCasino | Opt Out';
    document.body.style.backgroundColor = '#070A23';

    return () => {
      document.body.style.backgroundColor = '';

    };
  }, []);

  const validateInputs = () => {
    let isValid = true;

    if (name.trim().split(' ').length !== 2) {
      setNameError(t('contact:heading-3'));
      isValid = false;
    } else {
      setNameError('');
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(t('contact:heading-4'));
      isValid = false;
    } else {
      setEmailError('');
    }

    return isValid;
  };

  const fetchData = async (dataObj) => {
    try {
      const response = await fetchFormData('/api/optout.php', dataObj);
      if (
        typeof response.status !== 'undefined' &&
        response.status != null &&
        response.status === 'success'
      ) {
        setSubmitted(true);
        setTimeout(() => {
          setSubmitted(false);
          setName('');
          setEmail('');
        }, 5000);
      } else {
        console.error('Error:', response.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  function fetchFormData(url, param) {
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
      body: new URLSearchParams(param),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateInputs()) {
      const dataObj = { name: name, email: email, received: new Date().toJSON().slice(0, 10) };
      await fetchData(dataObj);
    }
  };

  return (
      <section
          className="flex flex-col-reverse overflow-hidden w-full items-center py-[120px] lg:pl-[25%] md:flex-row ">

        <div className=" mt-[-40px] text-center lg:text-start md:max-w-[554px] md:mt-0 z-[10]">
          {submitted ? (
              <div className="pb-10">
                <h1 className="text-2xl font-bold lg:text-[42px]">{t('contact:heading-1')}</h1>
                <p>{t('contact:subheading-1')}</p>
              </div>
          ) : (
              <>
                <h1 className="text-2xl font-bold lg:text-[42px]">{t('opt-out:heading')}</h1>
                <p className="mt-[10px] text-[13px] leading-[18px] lg:text-xl lg:mt-[30px]">
                  {t('opt-out:subheading')}
                </p>
                <form
                    onSubmit={handleSubmit}
                    className="flex flex-col items-start gap-[17px] mt-4 xl:mt-8"
                >
                  <input
                      placeholder="Enter a full name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="w-full font-sans px-6 py-2 text-black outline-0 rounded-full lg:py-[18px] lg:rounded-[10px]"
                  />
                  {nameError && <span className="text-red mt-[-10px] mb-[-10px]">{nameError}</span>}
                  <input
                      placeholder="Enter a full email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full font-sans px-6 py-2 text-black outline-0 rounded-full lg:py-[18px] lg:rounded-[10px]"
                  />
                  {emailError && <span className="text-red mt-[-10px] mb-[-10px]">{emailError}</span>}
                  <div className="m-auto mt-4 mb-10 p-[6px] lg:w-[320px] lg:m-0">
                    <Button type='submit' title={t('buttons:heading-8')} buttonClass="biggest"/>
                  </div>
                </form>
              </>
          )}
        </div>
        <img
            src={optOutImage}
            alt="opt-out-image"
            className="absolute top-0 right-0 lg:ml-[200px] z-[-5]"
        />
      </section>
  );
};

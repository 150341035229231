import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

export const CookiePolicy = () => {
  const { t } = useTranslation();


  useEffect(() => {
    document.title = 'SpinBattleCasino | Cookie Policy';
    document.body.style.backgroundColor = '#070A23';

    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);


  return (
    <section className="mt-10 px-4 mb-10 min-h-[70vh] lg:px-[330px]">
      <h1 className="text-3xl leading-[54px] lg:text-[40px] lg:text-center">{t('cookie:content')}</h1>
      <div className="text-base mt-[16px] lg:text-xl lg:leading-[32px] lg:mt-10">
        <p>{t('cookie:line1')}</p>
        <p className="text-[26px] font-bold mt-5">{t('cookie:line2')}</p>
        <p>{t('cookie:line3')}</p>
        <p className="text-[26px] font-bold mt-5">{t('cookie:line4')}</p>
        <p>{t('cookie:line5')}</p>
        <p className="ml-5">{t('cookie:line6')}</p>
        <p className="ml-5">{t('cookie:line7')}</p>
        <p className="ml-5">{t('cookie:line8')}</p>
        <p className="ml-5">{t('cookie:line9')}</p>
        <p className="text-[26px] font-bold mt-5">{t('cookie:line10')}</p>
        <p>{t('cookie:line11')}</p>
        <p className="ml-5">{t('cookie:line12')}</p>
        <p className="ml-5">{t('cookie:line13')}</p>
        <p className="ml-5">{t('cookie:line14')}</p>
        <p className="text-[26px] font-bold mt-5">{t('cookie:line15')}</p>
        <p>{t('cookie:line16')}</p>
        <p className="ml-5">{t('cookie:line17')}</p>
        <p className="ml-5">{t('cookie:line18')}</p>
        <p className="text-[26px] font-bold mt-5">{t('cookie:line19')}</p>
        <p>{t('cookie:line20')}</p>
      </div>
    </section>
  );
};

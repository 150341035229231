export const checkAuth = () => {
  return '/api/user/is_auth.php';
};

export const logout = () => {
  return '/api/user/logout.php';
};

export const registerUser = (id) => {
  return `/api/user/${id}.php`;
};

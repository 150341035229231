import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { GoBack } from '../components';



export const Disclaimer = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'SpinBattleCasino | Disclaimer';
    document.body.style.backgroundColor = '#070A23';
  }, []);

  return (
      <section className="mt-[50px] px-4 mb-10 lg:mb-[200px] min-h-[70vh] lg:px-[330px]">

        <h1 className="text-3xl leading-[54px] font-bold lg:text-[40px] lg:text-center">{t('disclaimer:heading7')}</h1>
          <div className="text-base mt-[16px] lg:text-xl lg:leading-[32px] lg:mt-10">

              <div className='mb-10'>
                  {t('disclaimer:content')}
              </div>

              <div className='mb-5'>
                  <span><span
                      className='font-bold text-[22px]'>{t('disclaimer:heading1')}</span>: {t('disclaimer:line1')}</span>
              </div>

              <div className='mb-5'>
                  <span><span
                      className='font-bold text-[22px]'>{t('disclaimer:heading2')}</span>: {t('disclaimer:line2')}</span>
              </div>

              <div className='mb-5'>
                  <span><span
                      className='font-bold text-[22px]'>{t('disclaimer:heading3')}</span>: {t('disclaimer:line3')}</span>
              </div>

              <div className='mb-5'>
                  <span><span
                      className='font-bold text-[22px]'>{t('disclaimer:heading4')}</span>: {t('disclaimer:line4')}</span>
              </div>

              <div className='mb-5'>
                  <span><span className='font-bold text-[22px]'>{t('disclaimer:heading5')}</span>: {t('disclaimer:line5')}</span>
              </div>

          </div>
      </section>
  );
};

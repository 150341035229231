import React, {useState, useEffect} from 'react';

import {useLocation} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {Button} from './Button.jsx';
import {Checkbox} from './Checkbox.jsx';

export const AgePopup = () => {
    const [isAgePopupOpen, setIsAgePopupOpen] = useState(false);
    const [isAgeConfirmed, setIsAgeConfirmed] = useState(localStorage.getItem('age-confirmed'));
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [showError, setShowError] = useState(false);
    const [ageRejected, setAgeRejected] = useState(false);
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        if (!isAgeConfirmed) {
            setIsAgePopupOpen(true);
        }
    }, [isAgeConfirmed]);

    const confirmAge = () => {
        if (isCheckboxChecked) {
            localStorage.setItem('age-confirmed', true);
            setIsAgeConfirmed(true);
            setIsAgePopupOpen(false);
        } else {
            setShowError(true);
        }
    };

    const rejectAgeConfirmation = () => {
        setAgeRejected(true);
    };

    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
        setShowError(false);
    };

    if (ageRejected) {
        return (
            <>
                <div
                    className="z-[9999] fixed h-full w-full inset-0 flex items-center justify-center bg-textBlack bg-opacity-60 ">
                    <div
                        className="mx-4 flex  flex-col items-center justify-center h-[369px] w-[631px] rounded-lg bg-textBlack shadow-md">
                        <h1 className="text-[38px] font-bold text-center leading-[50px]">
                            {t('agePopup:heading-1')}
                            <br/>
                            {t('agePopup:subheading-1')} <br/>
                            :(
                        </h1>
                    </div>
                </div>
            </>
        );
    }

    if (window.location.pathname === '/terms-and-conditions/') {
        return <></>;
    }

    return (
        <div>
            {isAgePopupOpen && (
                <div
                    className="z-[999999] inset-0 fixed h-full max-w-full flex items-center justify-center bg-textBlack bg-opacity-60">
                    <div
                        className="p-2 mx-4 pb-10  border-2 border-white md:mx-0 flex flex-col w-full lg:w-[631px] rounded-lg bg-textBlack shadow-md">
                        <h1 className="mt-[20px] md:mt-[70px] text-[38px] font-bold text-center leading-[46px]">
                            {t('agePopup:heading-2')}
                        </h1>
                        <h1 className="text-[38px] font-bold text-center">
                            {t('agePopup:subheading-2')}
                        </h1>
                        <div className="flex flex-col items-center mt-[35px] mx-auto">
                            <div className="flex items-center">
                                <Checkbox
                                    id="myCheckbox"
                                    checked={isCheckboxChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="ml-2 font-bold text-[16px]">
                                    {t('agePopup:heading-3')}
                                    <a className="underline font-bold" href="/terms/">
                                        {t('agePopup:subheading-3')}
                                    </a>
                                </label>
                            </div>
                            {!isCheckboxChecked && showError && (
                                <span className="text-[tomato] font-bold ml-1">{t('agePopup:heading-4')}</span>
                            )}
                        </div>
                        <div
                            className="flex items-center justify-center gap-[20px] md:gap-[55px] mt-[60px] text-[32px]">
                            <div className="max-w-[120px]  w-full lg:max-w-[240px]">
                                <Button
                                    buttonClass="mini"
                                    className='py-2'
                                    title={t('buttons:heading-3')}
                                    onClick={confirmAge}
                                    disabled={!isCheckboxChecked}
                                />
                            </div>
                            <div className="max-w-[120px] w-full lg:max-w-[240px]">
                                <Button buttonClass="mini" title={t('buttons:heading-4')} onClick={rejectAgeConfirmation}/>
                            </div>
                        </div>
                    </div>
                </div>

            )}
        </div>

    );
};

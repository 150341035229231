import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import {  bing, empty } from './locales/index';

const resources = {
  bing: bing,
  empty: empty,
};

localStorage.setItem('lng', 'bing');

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('lng'),
});

import React, {useContext, useState} from 'react';
import { Button } from './Button';
import { RootContext } from '../context/RootContext';
import { useTranslation } from 'react-i18next';

export const ThankYouPopup = () => {
  const { isThankYouPopupOpen } = useContext(RootContext);
  const { t } = useTranslation();

  return (
    isThankYouPopupOpen && (
      <div className="fixed top-0 w-full z-40">
        <div className="flex items-center justify-center relative w-full h-[100vh]">
          <div className="z-40 fixed h-full w-full inset-0 flex items-center justify-center bg-black bg-opacity-60"></div>
          <div className="w-full max-w-[679px] absolute z-50 mx-4  md:px-[83px] py-[45px] pb-10 md:mx-0 flex flex-col rounded-lg bg-textBlack  shadow-md">
            <h1 className="text-[30px] font-bold text-center leading-[54px]">
              {t('thankyou:heading-1')}
            </h1>
          </div>
        </div>
      </div>
    )
  );
};

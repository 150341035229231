import React, { useContext, useState } from 'react';
import { Button } from './Button.jsx';
import { RootContext } from '../context/RootContext.jsx';
import { useTranslation } from 'react-i18next';
import { registerUser } from '../api/api.js';

export const LoginPopup = () => {
  const { isLoginPopupOpen, closeLogin, switchPopup } = useContext(RootContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  const handleLogin = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let newErrors = {};

    if (!email.trim()) {
      newErrors.email = t('signup:heading-4');
    } else if (!emailRegex.test(email)) {
      newErrors.email = t('signup:heading-4');;
    }

    if (!password.trim()) {
      newErrors.password =  t('signup:heading-5');
    } else if (password.length < 6 || password.length > 20) {
      newErrors.password = t('signup:heading-13');
    }

    if (Object.keys(newErrors).length === 0) {
      const formData = new FormData();
      formData.append('user[email]', email);
      formData.append('user[password]', password);

      const url = registerUser('login');

      const options = {
        method: 'POST',
        body: formData,
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === 'success') {
            window.location.reload();
          } else {
            if (result.message === 'Password is incorrect') {
              newErrors.password = result.message;
            } else {
              newErrors.email = result.message;
            }
            setErrors(newErrors);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      setErrors(newErrors);
    }
  };

  return (
    isLoginPopupOpen && (
      <div className="fixed top-0 w-full z-40">
        <div className="flex items-center justify-center relative w-full h-[100vh]">
          <div
            onClick={closeLogin}
            className="z-40 fixed h-full w-full inset-0 flex items-center justify-center bg-black bg-opacity-60"
          ></div>
          <div className="max-w-[631px] w-full  absolute z-50 mx-4 p-4 md:px-[83px] py-[45px] pb-10 md:mx-0 flex flex-col rounded-lg bg-textBlack from-popupOrangePrimary to-popupOrangeSecondary shadow-md">
            <div onClick={closeLogin} className="absolute right-[10px] top-[10px] cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <rect
                  x="9.41211"
                  y="29.4121"
                  width="28.2843"
                  height="2.49567"
                  transform="rotate(-45 9.41211 29.4121)"
                  fill="#C4C4C4"
                />
                <rect
                  x="29.4121"
                  y="30.5879"
                  width="28.2843"
                  height="2.49567"
                  transform="rotate(-135 29.4121 30.5879)"
                  fill="#C4C4C4"
                />
              </svg>
            </div>
            <h1 className="text-[38px] font-bold text-center leading-[50px]">{t('buttons:heading-9')}</h1>
            <form className="text-black flex flex-col items-center justify-center mt-[35px] mx-auto gap-[26px] w-full">
              <input
                placeholder="Email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full rounded-[8px] font-montserrat px-6 py-4 text-black outline-0 lg:py-[18px]"
              />
              {errors.email && <p className="text-[tomato] font-bold mt-[-10px] mb-[-10px]">{errors.email}</p>}
              <input
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full rounded-[8px] font-montserrat px-6 py-4 text-black outline-0 lg:py-[18px]"
              />
              {errors.password && (
                <p className="text-[tomato] font-bold mt-[-10px] mb-[-10px]">{errors.password}</p>
              )}
            </form>
            <div className="flex items-center justify-center gap-[25px] md:gap-[20px] mt-[30px] text-[32px]">
              <div className="w-full">
                <Button buttonClass="large" title={t('buttons:heading-2')} type="submit" onClick={handleLogin} />
              </div>
            </div>
            <label className="m-auto mt-8 font-bold">
              Don’t? have an account?{' '}
              <span className="underline cursor-pointer font-bold" onClick={switchPopup}>
                Sign up
              </span>
            </label>
          </div>
        </div>
      </div>

    )
  );
};

import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';

import { RootContext } from '../context/RootContext.jsx';

import { useLocation } from 'react-router-dom';
import Hero from './../assets/images/bg-img1.png'

import { Button } from './Button.jsx';


import logo from '../assets/images/logo.png';

export const Header = () => {
    const { t } = useTranslation();
  const location = useLocation();
  const {loggedIn, openSignUp, openLogin, userLogout, toggleMenu, isMenuOpen } = useContext(RootContext);
  const isNavbarHidden = location.pathname === '/lp/';
  
  return (
      <header
          className="flex items-center justify-center bg-brown-secondary h-18 w-full lg:justify-center relative lg:h-full lg:p-2">


          <div className='flex mt-3 mb-3 lg:mr-[30px]'>
              {
                  isNavbarHidden
                      ? <img src={logo} alt="logo"/>

                      : <a href="/" className="">
                          <img src={logo} alt="logo"/>
                      </a>
              }
          </div>

          <div
              className={
                  isMenuOpen
                      ? 'flex flex-col z-[30] overflow-y-scroll align-center sm:h-[100vh] fixed top-0 bg-textBlack lg:fixed w-full h-full'
                      : 'hidden lg:flex justify-between'
              }
          >
              <div
                  className={
                      isNavbarHidden
                          ? 'hidden'
                          : 'text-[14px] w-full flex flex-col font-bold mt-[150px] lg:mt-2 uppercase items-center gap-[53px] lg:flex-row lg:justify-center'
                  }
              >
                  <a href="/" className="transition hover:text-tertiaryOrange">
                      Home
                  </a>

                  <a href="/about/" className="transition hover:text-tertiaryOrange">
                      About
                  </a>
                  <a href="/disclaimer/" className="transition hover:text-tertiaryOrange">
                      Disclaimer
                  </a>
                  <a href="/contact/" className="transition hover:text-tertiaryOrange">
                      Contact
                  </a>
              </div>
              {loggedIn === true ? (
                  <div
                      className="w-full flex flex-col items-center justify-center gap-[40px] mt-[33px] lg:flex-row lg:justify-center lg:mt-0 lg:p-2 lg:w-1/4">
                      <div className="w-full max-w-[140px] flex text-xl items-center justify-center lg:min-w-[140px] lg:ml-[54px] lg:text-base">
                          <Button onClick={userLogout} title={t('buttons:heading-10')} buttonClass="mini"/>
                      </div>
                  </div>
              ) : (
                  <div
                      className="flex items-center justify-center m-5 mt-[100px] gap-[34px] lg:flex-row  lg:ml-[54px] lg:mt-5 lg:flex-grow lg:flex-shrink-0 lg:w-1/4">

                      <div className="w-full min-w-[90px] lg:min-w-[130px]">
                          <Button onClick={openSignUp} title={t('buttons:heading-6')} buttonClass="mini"/>
                      </div>

                      <div className="w-full min-w-[90px] lg:min-w-[130px]">
                          <Button onClick={openLogin} title={t('buttons:heading-9')} buttonClass="mini"/>
                      </div>

                  </div>
              )}
          </div>


          <div
              className="cursor-pointer absolute right-[20px] top-[35px] z-[35] lg:hidden"
              onClick={toggleMenu}
          >
              {isMenuOpen ? (
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="22"
                      viewBox="0 0 42 43"
                      fill="none"
                  >
                      <rect
                          x="30.625"
                          y="33.3606"
                          width="29.6985"
                          height="2.47487"
                          rx="1.23744"
                          transform="rotate(-135 30.625 33.3606)"
                          fill="#fff"
                      />
                      <rect
                          x="9.625"
                          y="31.6104"
                          width="29.6985"
                          height="2.47487"
                          rx="1.23744"
                          transform="rotate(-45 9.625 31.6104)"
                          fill="#fff"
                      />
                  </svg>
              ) : (
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="12"
                      viewBox="0 0 42 26"
                      fill="none"
                  >
                      <rect width="41.6667" height="2.88889" rx="1.44444" fill="white"/>
                      <rect y="11.5555" width="41.6667" height="2.88889" rx="1.44444" fill="white"/>
                      <rect y="23.1111" width="41.6667" height="2.88889" rx="1.44444" fill="white"/>
                  </svg>
              )}
          </div>
      </header>
  );
};

import React from 'react';

import classnames from 'classnames';

export const Button = (props) => {
  const { title, onClick, buttonClass, type, anchorLink, anchorHref } = props;

  const buttonStyles = classnames('w-full', {
    'transition hover:text-purple rounded-full py-2 bg-buttonOrangePrimary w-full max-w-full':
      buttonClass === 'primary',
    'transition hover:text-purple rounded-full py-2 bg-buttonOrangeSecondary w-full max-w-full':
      buttonClass === 'secondary',
    'transition hover:shadow-[0px_0px_10px_10px_rgba(255,101,175,0.43)] hover:text-purple font-bold text-brown rounded-full py-2 px-8 h-full lg:text-[42px] bg-[linear-gradient(96deg,#FFA800_10.17%,#FFC56F_31.42%,#FFBD6F_47.15%,#FFDBA6_59.47%,#FFBB7C_71.8%,#FF7E47_95.18%)]':
      buttonClass === 'tertiaryTransparent',
    'transition hover:text-tertiaryOrange border-2 rounded-3xl border-solid border-primaryOrange py-2':
      buttonClass === 'primaryTransparent',
    'transition hover:text-tertiaryOrange border-2 rounded-3xl border-solid border-secondaryOrange py-2':
      buttonClass === 'secondaryTransparent',
    'transition hover:text-darkBg hover:bg-primaryOrange rounded-full text-[22px] py-2 bg-transparent border-2 border-solid border-primaryOrange':
      buttonClass === 'playNow',
    'transition hover:shadow-[0px_0px_11px_10px_rgba(255,101,175,0.43)] h-full uppercase font-montserrat font-extrabold text-white text-[14px] rounded-[2px] py-[17px] px-[64px] h-full lg:py-[12px]  bg-[linear-gradient(90deg,#9444BF,#6300C6)]':
        buttonClass === 'long',
    'transition hover:shadow-[0px_0px_11px_10px_rgba(255,101,175,0.43)] h-full uppercase font-montserrat font-extrabold text-white text-[14px] rounded-[8px] py-[17px] px-[24px] h-full lg:py-[12px]  bg-[linear-gradient(90deg,#9444BF,#6300C6)]':
        buttonClass === 'mini',
    'transition hover:shadow-[0px_0px_11px_10px_rgba(255,101,175,0.43)] max-w-full flex items-center justify-center uppercase font-montserrat font-extrabold text-white md:text-[16px] text-[13px] rounded-[8px] md:py-4 py-2 bg-[linear-gradient(90deg,#D62CE8,#6300C6)]':
        buttonClass === 'large',
    'transition hover:shadow-[0px_0px_11px_10px_rgba(255,101,175,0.43)] h-full uppercase font-montserrat font-extrabold text-white text-[18px] rounded-[8px] py-[17px] px-[44px] h-full lg:py-[19px] lg:px-[52px] bg-[linear-gradient(90deg,#9444BF,#6300C6)]':
        buttonClass === 'biggest',
  });

  if (buttonClass === 'tertiaryTransparent') {
    return (
      <div className="transition rounded-full border-4 border-solid border-[#FE9809] p-[6px] m-auto shadow-[0px_0px_5px_5px_rgba(255,101,175,0.43)]">
        <button className={buttonStyles} onClick={onClick} type={type}>
          {title}
        </button>
      </div>
    );
  }

  if (buttonClass === 'tertiaryTransparentLink') {
    return (
      <div className="flex items-center transition rounded-full border-4 border-solid border-[#FE9809] h-[60px] lg:h-[100px] p-[6px] m-auto shadow-[0px_0px_5px_5px_rgba(255,101,175,0.43)]">
        <a className={buttonStyles} type={type} href={anchorHref}>
          {title}
        </a>
      </div>
    );
  }

  if (anchorLink) {
    return (
      <a className={buttonStyles} href={anchorHref}>
        {title}
      </a>
    );
  } else {
    return (
      <button className={buttonStyles} onClick={onClick} type={type}>
        {title}
      </button>
    );
  }
};

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const PrivacyPolicy = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'SpinBattleCasino | Privacy Policy';

    document.body.style.backgroundColor = '#070A23';

    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);
  const renderLines = () => {
    const lines = [];
    const lng = localStorage.getItem('lng');

    if (lng === 'bing') {
      for (let i = 1; i <= 36; i++) {
        const lineKey = `privacy:line${i}`;
        if (t(lineKey)) {
          lines.push(<p key={`line-${i}`}>{t(lineKey)}</p>);
        }
      }
    } else {
      for (let i = 1; i <= 36; i++) {
        const lineKey = `privacy:line${i}`;
        if (t(lineKey)) {
          lines.push(<p key={`line-${i}`}>{t(lineKey)}</p>);
        }
      }
    }

    return lines;
  };

  return (
      <section className="mt-10 px-4 mb-10 min-h-[70vh] lg:px-[330px]">
        <h1 className="text-3xl leading-[54px] font-bold lg:text-[40px] lg:text-center">{t('privacy:content')}</h1>
        <div className="text-base mt-[16px] lg:text-xl lg:leading-[32px] lg:mt-10">
          <p  className="text-[26px] font-bold mt-5">{t('privacy:line1')}</p>
          <p>{t('privacy:line2')}</p>
          <p  className="text-[26px] font-bold mt-5">{t('privacy:line3')}</p>
          <p>{t('privacy:line4')}</p>
          <p  className="text-[26px] font-bold mt-5">{t('privacy:line5')}</p>
          <p>{t('privacy:line6')}</p>
          <p  className="text-[26px] font-bold mt-5">{t('privacy:line7')}</p>
          <p>{t('privacy:line8')}</p>
          <p>{t('privacy:line9')}</p>
          <p className="ml-5">{t('privacy:line10')}</p>
          <p className="ml-5">{t('privacy:line11')}</p>
          <p className="ml-5">{t('privacy:line12')}</p>
          <p  className="text-[26px] font-bold mt-5">{t('privacy:line13')}</p>
          <p className="ml-5">{t('privacy:line14')}</p>
          <p className="ml-5">{t('privacy:line15')}</p>
          <p className="ml-5">{t('privacy:line16')}</p>
          <p className="ml-5">{t('privacy:line17')}</p>
          <p>{t('privacy:line18')}</p>
          <p  className="text-[26px] font-bold mt-5">{t('privacy:line19')}</p>
          <p>{t('privacy:line20')}</p>
          <p>{t('privacy:line21')}</p>
          <p className="ml-5">{t('privacy:line22')}</p>
          <p className="ml-5">{t('privacy:line23')}</p>
          <p className="ml-5">{t('privacy:line24')}</p>
          <p className="ml-5">{t('privacy:line25')}</p>
          <p className="ml-5">{t('privacy:line26')}</p>
          <p className="ml-5">{t('privacy:line27')}</p>
          <p  className="text-[26px] font-bold mt-5">{t('privacy:line28')}</p>
          <p>{t('privacy:line29')}</p>
          <p>{t('privacy:line30')}</p>
          <p>{t('privacy:line31')}</p>
          <p  className="text-[26px] font-bold mt-5">{t('privacy:line32')}</p>
          <p>{t('privacy:line33')}</p>
          <p  className="text-[26px] font-bold mt-5">{t('privacy:line34')}</p>
          <p>{t('privacy:line35')}</p>
          <p>{t('privacy:line36')}</p>
        </div>
      </section>
  );
};

import React from 'react';
import ReactDOM from 'react-dom/client';

import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { RootProvider } from './context/RootContext.jsx';
import './i18n/i18n.js';

import { App } from './App';

import './index.css';

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
            <BrowserRouter>
                <RootProvider>
                    <CookiesProvider>
                        <App />
                    </CookiesProvider>
                </RootProvider>
            </BrowserRouter>
    </React.StrictMode>,
);
